import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TextInputDialogData } from '@qaroni-core/types';
import { InputValidation } from '@qaroni-core/utils';

@Component({
  selector: 'qaroni-text-input-dialog',
  templateUrl: './text-input-dialog.component.html',
  styleUrls: ['./text-input-dialog.component.scss']
})
export class TextInputDialogComponent implements OnInit {
  public textForm: FormGroup;

  private textSkeletonForm = {
    text: ['', [Validators.required]],
  };

  constructor(
    public dialogRef: MatDialogRef<TextInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TextInputDialogData,
    private fb: FormBuilder
  ) {
    this.createTextForm();
  }

  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close(null);
  }

  private createTextForm(): void {
    this.textForm = this.fb.group(this.textSkeletonForm);
  }

  get InputValidation(): any {
    return InputValidation;
  }

  get text(): AbstractControl {
    return this.textForm.get('text');
  }

  get validatedForm(): boolean {
    return this.textForm.dirty && this.textForm.valid;
  }

  public onSubmit(): void {
    if (this.validatedForm && this.text && this.text.value) {
      this.dialogRef.close(this.text.value);
    }
  }
}
