<button mat-button type="button" class="close-icon" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>{{ data }}</div>

<form [formGroup]="sendReportClientsForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="row justify-content-center">
      <div class="col-10 col-sm-8 col-md-8 mb-2">
        <mat-form-field appearance="outline">
          <mat-label>{{ "Correo electrónico" | translate }}</mat-label>
          <input
            matInput
            formControlName="email"
            autocomplete="off"
            type="email"
          />
          <mat-error
            align="start"
            *ngIf="InputValidation.checkInputErrors(email)"
          >
            <qaroni-input-error-messages
              [fc]="email"
            ></qaroni-input-error-messages>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="row justify-content-end align-items-center">
      <div class="col-auto">
        <button mat-button color="warn" (click)="onCancelClick()" type="button">
          Cancelar
        </button>
      </div>
      <div class="col-auto">
        <button
          mat-flat-button
          color="accent"
          [disabled]="!validatedForm"
          type="submit"
        >
          <mat-icon>forward_to_inbox</mat-icon>
          Enviar
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</form>
