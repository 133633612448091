<form [formGroup]="branchIdForm">
  <mat-form-field appearance="outline" class="ff-no-bottom-space">
    <mat-label>
      {{ "Sucursal" | translate }}
    </mat-label>
    <mat-select
      formControlName="branchId"
      (selectionChange)="onBranchChange($event)"
    >
      <mat-option value="">{{ "Todos" | translate }}</mat-option>
      <mat-option [value]="branch?.branchId" *ngFor="let branch of branches">{{
        branch?.tradename
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</form>
