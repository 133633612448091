import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import {
  ChargeStatusArray,
  ChargeStatusEnum
} from '@qaroni-app/application/charges/types/charge-status.enum';
import { AllAppService } from '@qaroni-core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'qaroni-charge-status-selector',
  templateUrl: './charge-status-selector.component.html',
  styleUrls: ['./charge-status-selector.component.scss'],
})
export class ChargeStatusSelectorComponent implements OnInit, OnDestroy {
  private chargeStatusSkeleton = {
    status: ['', Validators.required],
  };
  public chargesForm: FormGroup = this.fb.group(this.chargeStatusSkeleton);

  constructor(
    private route: ActivatedRoute,
    private allApp: AllAppService,
    private fb: FormBuilder
  ) {}

  private subs: Subscription = new Subscription();

  private queryParamsHasPage: boolean;

  ngOnInit(): void {
    this.subs.add(this.route.queryParamMap.subscribe(this.getQueryParamMap));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get ChargeStatusArray(): string[] {
    return ChargeStatusArray;
  }

  get status(): AbstractControl {
    return this.chargesForm.get('status');
  }

  private getQueryParamMap = (queryParamMap: ParamMap): void => {
    this.queryParamsHasPage = queryParamMap.has('page');
    if (queryParamMap.has('status') && queryParamMap.get('status').trim()) {
      const status = queryParamMap.get('status').trim();
      this.status.setValue(this.getEnumByKey(status));
    } else {
      this.status.setValue('');
    }
  }

  getEnumByKey(status: string): ChargeStatusEnum {
    if (status) {
      if (ChargeStatusEnum.PAID === status) {
        return ChargeStatusEnum.PAID;
      } else if (ChargeStatusEnum.RETURN === status) {
        return ChargeStatusEnum.RETURN;
      } else if (ChargeStatusEnum.SENT === status) {
        return ChargeStatusEnum.SENT;
      } else if (ChargeStatusEnum.PENDING === status) {
        return ChargeStatusEnum.PENDING;
      }
    }
    return null;
  }

  onStatusChange(event: any): void {
    if (event && event.value) {
      const queryParams: Params = {
        status: this.status.value,
      };
      if (this.queryParamsHasPage) {
        queryParams.page = 1;
      }
      this.allApp.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      });
    } else {
      this.status.setValue('');
    }
  }
}
