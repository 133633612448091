import { Pipe, PipeTransform } from '@angular/core';
import {
  ChargeStatusEnum,
  ChargeStatusInfo
} from '@qaroni-app/application/charges/types/charge-status.enum';

@Pipe({
  name: 'chargeStatus',
})
export class ChargeStatusPipe implements PipeTransform {
  transform(value: ChargeStatusEnum): string {
    if (value) {
      const key = value.trim().toUpperCase();
      for (const iterator of ChargeStatusInfo) {
        if (Object.keys(iterator)[0] === key) {
          return iterator[key].name;
        }
      }
    }
    return null;
  }
}
