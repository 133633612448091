import { Injectable } from '@angular/core';
import { StorageUtilsService } from '@qaroni-core/services';
import { Observable, Subject } from 'rxjs';
import { MerchantsType } from '../types/merchant';

@Injectable({
  providedIn: 'root',
})
export class MerchantStorageService {
  private merchantsType: MerchantsType;
  private merchantsTypeSubject = new Subject<MerchantsType>();

  private lsKey = 'merchantsType';

  constructor(private storageUtilsService: StorageUtilsService) {}

  public merchantsType$(): Observable<MerchantsType> {
    return this.merchantsTypeSubject.asObservable();
  }

  public get(): MerchantsType {
    if (this.storageUtilsService.validateInLocalStorage(this.lsKey)) {
      return JSON.parse(localStorage.getItem(this.lsKey));
    } else {
      return this.merchantsType;
    }
  }

  public set(merchantsType: MerchantsType): void {
    if (this.storageUtilsService.validateLocalStorage) {
      localStorage.setItem(this.lsKey, JSON.stringify(merchantsType));
    } else {
      this.merchantsType = merchantsType;
    }
    this.merchantsTypeSubject.next(merchantsType);
  }

  public reset(): void {
    if (this.storageUtilsService.validateInLocalStorage(this.lsKey)) {
      localStorage.removeItem(this.lsKey);
    } else {
      this.merchantsType = null;
    }
    this.merchantsTypeSubject.next(null);
  }

  get hasMerchantsType(): boolean {
    if (this.get() && this.get().type) {
      return true;
    }
    return false;
  }

  get getMerchantstype(): string {
    return this.get().type;
  }
}
