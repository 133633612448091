<div class="mb-2 mb-sm-2 text-right text-sm-left">
  <form [formGroup]="chargesForm">
    <mat-form-field
      appearance="outline"
      hideRequiredMarker
      class="ff-no-bottom-space moz-suffix"
    >
      <mat-label>
        {{ "Estado" | translate }}
      </mat-label>
      <mat-select
        formControlName="status"
        required
        (selectionChange)="onStatusChange($event)"
      >
        <mat-option
          *ngFor="let status of ChargeStatusArray"
          [value]="status"
          >{{ status | chargeStatus }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </form>
</div>
