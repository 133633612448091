export const InputConfig = {
  user: {
    password: {
      minLength: 8
    }
  },
  address: {
    alias: {
      maxLength: 40
    },
    line: {
      maxLength: 80
    },
    postalCode: {
      maxLength: 8
    },
    city: {
      maxLength: 60
    },
    stateProvince: {
      maxLength: 50
    },
    country: {
      maxLength: 2
    }
  },
  promotionalCode: {
    minLength: 12,
    maxLength: 12,
    pattern: '^[0-9]{12}$'
  }
};
