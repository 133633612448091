import { Component, OnInit } from '@angular/core';
import { TransactionService } from '@qaroni-app/application/transactions';
import { PaginationLinks, TotalAmounts } from '@qaroni-core/types';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'qaroni-totals-merchant-cards',
  templateUrl: './totals-merchant-cards.component.html',
  styleUrls: ['./totals-merchant-cards.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class TotalsMerchantCardsComponent implements OnInit {
  public totalAmounts$: Observable<TotalAmounts> = this.transactionService
    .getTotalAmounts$()
    .pipe(shareReplay(1));

  public paginationLinks$: Observable<PaginationLinks> = this.transactionService
    .getPaginationLinks$()
    .pipe(shareReplay(1));

  constructor(private transactionService: TransactionService) {}

  ngOnInit(): void {}
}
