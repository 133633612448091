import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  Transaction,
  TransactionDataSource,
  TransactionService,
  TransactionTypeEnum
} from '@qaroni-app/application/transactions';
import { AllAppService } from '@qaroni-core/services';
import { ConfirmationDialogData } from '@qaroni-core/types';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../dialogs';

@Component({
  selector: 'qaroni-transactions-table',
  templateUrl: './transactions-table.component.html',
  styleUrls: ['./transactions-table.component.scss'],
})
export class TransactionsTableComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private subs: Subscription = new Subscription();
  public transaction$: Observable<Transaction> = this.transactionService
    .getTransaction$()
    .pipe(shareReplay(1));

  submitting = false;

  @Input() showPaginator = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Transaction>;
  public dataSource: TransactionDataSource;

  public displayedColumns: string[] = [
    'creationDate',
    'code',
    'fullAmount',
    'amount',
    'description',
    'name',
    'type',
    'result',
    'actions',
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transactionService: TransactionService,
    private allApp: AllAppService
  ) {}

  ngOnInit(): void {
    this.dataSource = new TransactionDataSource(
      this.route,
      this.router,
      this.transactionService
    );
    this.subs.add(this.transaction$.subscribe(this.getTransaction));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  private enableLoading(): void {
    this.allApp.progressBar.show();
    this.submitting = true;
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
    this.submitting = false;
  }

  showReturnButton(row: Transaction): boolean {
    return (
      row &&
      !row.returnTransactionId &&
      row.amount > 0 &&
      TransactionTypeEnum.SALE === row.type
    );
  }

  returnTransaction(row: Transaction): void {
    if (this.showReturnButton(row)) {
      this.openDialogConfirmation(row);
    }
  }

  private getTransaction = (transaction: Transaction): void => {
    this.disableLoading();
    if (transaction && transaction.merchantId) {
      const queryParams: Params = { ...this.route.snapshot.queryParams };
      this.transactionService.getTransactions(
        transaction.merchantId,
        queryParams
      );
    }
  }

  openDialogConfirmation(row: Transaction): void {
    const confirmationData: ConfirmationDialogData = {
      title: this.allApp.translate.instant('¿Crear operación de devolución?'),
      message: this.allApp.translate.instant(
        'Se creará una operación de devolución que anula la operación seleccionada. Esta acción devuelve el saldo al cliente. ¿Está seguro?'
      ),
      confirmMatIcon: `delete`,
      confirmText: this.allApp.translate.instant('Sí, Devolver'),
    };

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.width = '700px';
    matDialogConfig.maxWidth = '90vw';
    matDialogConfig.panelClass = 'style-close-dialog';
    matDialogConfig.data = confirmationData;

    const dialog = this.allApp.dialog.open(
      ConfirmationDialogComponent,
      matDialogConfig
    );

    this.subs.add(
      dialog.afterClosed().subscribe((confirmation) => {
        if (confirmation === true) {
          this.enableLoading();
          this.transactionService.returnTransaction(
            row.merchantId,
            row.transactionId
          );
        }
      })
    );
  }
}
