import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OAuthGuard } from '@qaroni-core/guards';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'auth' },
  {
    path: 'auth',
    loadChildren: () =>
      import('@qaroni-app/application/auth/auth.module').then(
        (m) => m.AuthModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@qaroni-app/application/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canLoad: [OAuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('@qaroni-app/application/views/views.module').then(
        (m) => m.ViewsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
