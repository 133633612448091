

<ng-container *ngIf="merchant?.imageUrl; else imageDefault">
  <img [src]="merchant?.imageUrl" [alt]="merchant?.tradename" class="img-fluid">
</ng-container>
<ng-template #imageDefault>
  <div class="d-flex justify-content-center align-items-center container-icon">
    <mat-icon>add_photo_alternate</mat-icon>
  </div>
</ng-template>
