import { Component, OnInit } from '@angular/core';
import { AppConfig, ImagesConfig } from '@qaroni-core/config';
import { AllAppService } from '@qaroni-core/services';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'qaroni-outside-toolbar',
  templateUrl: './outside-toolbar.component.html',
  styleUrls: ['./outside-toolbar.component.scss'],
})
export class OutsideToolbarComponent implements OnInit {
  public progressBar$: Observable<boolean> = this.allApp.progressBar
    .getProgressBar$()
    .pipe(shareReplay(1));

  public AppConfig = AppConfig;
  public ImagesConfig = ImagesConfig;

  constructor(private allApp: AllAppService) {}

  ngOnInit(): void {}
}
