export class KeysConstants {
    /* Merchants key constants. */
    public static KEY_SIGNATURE_ID = 'signatureId';

    public static KEY_PARAMS = 'params';
    public static KEY_DATE_FROM = 'dateFrom';
    public static KEY_DATE_TO = 'dateTo';
    public static KEY_SEARCH = 'search';


}
