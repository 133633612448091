<div class="container-fluid px-0">
  <div class="row no-gutters">
    <div class="col-12 bg-footer text-white py-4 py-0">
      <div class="container">
        <div class="justify-content-center justify-content-sm-between align-items-center">
          <div class="text-center">
            <img id="logo-owner" [src]="ImagesConfig.footerOwner" [alt]="ImagesConfig.footerOwnerAlt"
              class="img-fluid m-2">
            <img id="logo-partner" [src]="ImagesConfig.footerPartner" [alt]="ImagesConfig.footerPartnerAlt"
              class="img-fluid m-2">
            <img id="logo-camara" [src]="ImagesConfig.footerCamaraCaceres" [alt]="ImagesConfig.footerCamaraCaceresAlt"
              class="img-fluid m-2">
            <img id="logo-qaroni" [src]="ImagesConfig.footerQaroni" [alt]="ImagesConfig.footerQaroniAlt"
              class="img-fluid m-2">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
