import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiQBonoEnv } from '@qaroni-core/config';
import { Observable } from 'rxjs';
import { TransactionOperation } from '../types/transaction';
import { TransactionReportData } from '../types/transaction-report-data';

@Injectable({
  providedIn: 'root',
})
export class TransactionHttpService {
  constructor(private http: HttpClient) {}

  public getTransactions$(
    merchantID: number | string,
    params?: Params
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/transactions/dashboards`;

    return this.http.get(url, { observe: 'response', params });
  }

  public createTransaction$(
    merchantID: number | string,
    transaction: TransactionOperation
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/transactions`;

    return this.http.post(url, transaction, { observe: 'response' });
  }

  public getTotalAmounts$(params?: Params): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/transactions/totals`;

    return this.http.get(url, { observe: 'response', params });
  }

  sendTransactionReportByParams$(
    merchantId: number,
    reportData: TransactionReportData
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantId}/transactions/excel`;

    return this.http.post(url, reportData, { observe: 'response' });
  }

  returnTransaction$(
    merchantId: number,
    transactionId: number
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantId}/transactions/${transactionId}/corrections`;

    return this.http.post(url, null, { observe: 'response' });
  }
}
