export enum ChargeStatusEnum {
  PAID = 'PAID',
  PENDING = 'PENDING',
  RETURN = 'RETURN',
  SENT = 'SENT',
}

export type ChargeStatusType =
  | ChargeStatusEnum.PAID
  | ChargeStatusEnum.PENDING
  | ChargeStatusEnum.RETURN
  | ChargeStatusEnum.SENT;

export const ChargeStatusArray = [
  ChargeStatusEnum.PAID,
  ChargeStatusEnum.PENDING,
  ChargeStatusEnum.RETURN,
  ChargeStatusEnum.SENT,
];

export const ChargeStatusInfo = [
  {
    [ChargeStatusEnum.PAID]: {
      name: 'Pagado',
    },
  },
  {
    [ChargeStatusEnum.PENDING]: {
      name: 'Pendiente',
    },
  },
  {
    [ChargeStatusEnum.RETURN]: {
      name: 'Regresado',
    },
  },
  {
    [ChargeStatusEnum.SENT]: {
      name: 'Enviado',
    },
  },
];
