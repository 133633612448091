export class BuildHttpData {
  constructor() {}

  public static buildMultipart(
    multipartName: string,
    dataContents: any
  ): FormData {
    const dataMultipart = new FormData();
    dataMultipart.append(multipartName, JSON.stringify(dataContents));
    return dataMultipart;
  }

  public static buildMultipartWithImages(
    dataMultipart: FormData,
    multipartName: string,
    files: File[]
  ): FormData {
    if (files.length) {
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        // console.log(element.size);
        // console.log(element.type);
        dataMultipart.append(multipartName, element, element.name);
      }
    }
    return dataMultipart;
  }

  public static createMultipartFiles(name: string, file: File): FormData {
    const formData: FormData = new FormData();
    formData.append(name, file, file.name);
    return formData;
  }
}
