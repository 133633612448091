import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/application/auth';
import { DataToGenerateReportVO } from '@qaroni-app/application/clients/components/models/DataToGenerateReportVO';
import { AllAppService, CommonsHttpService } from '@qaroni-core/services';
import { PaginationLinks } from '@qaroni-core/types';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Merchant } from '../types/merchant';
import { MerchantReportData } from '../types/merchant-report-data';
import { MerchantSnackbars } from '../types/merchant-snackbars.config';
import { MerchantHttpService } from './merchant-http.service';
import { MerchantStorageService } from './merchant-storage.service';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  protected readonly merchantsSubject = new Subject<Merchant[]>();
  protected readonly merchantSubject = new Subject<Merchant>();
  protected readonly paginationLinksSubject = new Subject<PaginationLinks>();

  protected readonly merchantsReportSubject = new Subject<boolean>();
  protected readonly registerMerchantsSubject = new Subject<string[]>();

  protected readonly flagSubject = new Subject<boolean>();

  constructor(
    private allApp: AllAppService,
    private merchantHttp: MerchantHttpService,
    private commonsHttp: CommonsHttpService,
    public oAuthStorage: OAuthStorageService,
    public merchantStorage: MerchantStorageService
  ) {}

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  public hasLatLng(merchant: Merchant): boolean {
    if (merchant?.latitude && merchant?.longitude) {
      return true;
    }
    return false;
  }

  public getLatLng(merchant: Merchant): google.maps.LatLngLiteral {
    if (this.hasLatLng(merchant)) {
      const latLng: google.maps.LatLngLiteral = {
        lat: merchant?.latitude,
        lng: merchant?.longitude,
      };
      return latLng;
    }
    return null;
  }

  // ==========================================================================================
  // Get Merchants
  // ==========================================================================================

  public getMerchants$(): Observable<Merchant[]> {
    return this.merchantsSubject.asObservable();
  }

  public resetMerchants(): void {
    return this.merchantsSubject.next(null);
  }

  public getMerchants(params?: Params): void {
    this.enableLoading();
    this.merchantHttp
      .getMerchants$(params)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({
        next: this.nextGetMerchants,
        error: this.errorGetMerchants,
      });
  }

  private nextGetMerchants = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchants: Merchant[] = data.body.result;
      this.merchantsSubject.next(merchants);
      const paginationLinks: PaginationLinks = data.body.links;
      this.paginationLinksSubject.next(paginationLinks);
    } else {
      this.merchantsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetMerchants = (error: HttpErrorResponse): void => {
    this.merchantsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Merchant
  // ==========================================================================================

  public getMerchant$(): Observable<Merchant> {
    return this.merchantSubject.asObservable();
  }

  public getMerchant(merchantID: number | string): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.merchantHttp
        .getMerchant$(merchantID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetMerchant,
          error: this.errorGetMerchant,
        });
    }
  }

  private nextGetMerchant = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetMerchant = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Update Merchant
  // ==========================================================================================
  public updateMerchant(merchant: Merchant): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .updateMerchant$(this.oAuthStorage.getMerchantID, merchant)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextUpdateMerchant,
          error: this.errorUpdateMerchant,
        });
    }
  }

  private nextUpdateMerchant = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorUpdateMerchant = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Add Image
  // ==========================================================================================
  public addImage(dataMultipart: FormData): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .addImage$(this.oAuthStorage.getMerchantID, dataMultipart)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({ next: this.nextAddImage, error: this.errorAddImage });
    }
  }

  private nextAddImage = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorAddImage = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Delete Image
  // ==========================================================================================
  public deleteImage(): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .deleteImage$(this.oAuthStorage.getMerchantID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextDeleteImage,
          error: this.errorDeleteImage,
        });
    }
  }

  private nextDeleteImage = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorDeleteImage = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Pagination Links
  // ==========================================================================================

  public getPaginationLinks$(): Observable<PaginationLinks> {
    return this.paginationLinksSubject.asObservable();
  }

  // ==========================================================================================
  // Cancel Merchant
  // ==========================================================================================
  public cancelMerchant(merchantID: number | string): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.merchantHttp
        .cancelMerchant$(merchantID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextCancelMerchant,
          error: this.errorCancelMerchant,
        });
    }
  }

  private nextCancelMerchant = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
      this.allApp.snackbar.open(
        MerchantSnackbars.successCancelled.message,
        MerchantSnackbars.successCancelled.closeBtn,
        MerchantSnackbars.successCancelled.config
      );
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorCancelMerchant = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Active Merchant
  // ==========================================================================================
  public activeMerchant(merchantID: number | string): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.merchantHttp
        .activeMerchant$(merchantID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextActiveMerchant,
          error: this.errorActiveMerchant,
        });
    }
  }

  private nextActiveMerchant = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
      this.allApp.snackbar.open(
        MerchantSnackbars.successActivated.message,
        MerchantSnackbars.successActivated.closeBtn,
        MerchantSnackbars.successActivated.config
      );
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorActiveMerchant = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Resend Email Activation
  // ==========================================================================================
  public resendEmailActivation(merchantID: number | string): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.merchantHttp
        .resendEmailActivation$(merchantID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextResendEmailActivation,
          error: this.errorResendEmailActivation,
        });
    }
  }

  private nextResendEmailActivation = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
      this.allApp.snackbar.open(
        MerchantSnackbars.successResendEmailActivation.message,
        MerchantSnackbars.successResendEmailActivation.closeBtn,
        MerchantSnackbars.successResendEmailActivation.config
      );
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorResendEmailActivation = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Merchants Report CSV File
  // ==========================================================================================

  public sendMerchantsCsvFile(
    dataToGenerateReportVO: DataToGenerateReportVO
  ): any {
    this.enableLoading();
    return this.merchantHttp
      .sendMerchantsCsvFile$(dataToGenerateReportVO)
      .pipe(finalize(() => this.disableLoading()));
  }

  // ==========================================================================================
  // Merchants Report
  // ==========================================================================================

  public getMerchantsReport$(): Observable<boolean> {
    return this.merchantsReportSubject.asObservable();
  }

  // ==========================================================================================
  // Merchants Report by IAE
  // ==========================================================================================

  public sendReportByIAE(reportData: MerchantReportData): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.merchantHttp
        .sendReportByIAE$(reportData)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextSendReportByIAE,
          error: this.errorSendReportByIAE,
        });
    }
  }

  private nextSendReportByIAE = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus204(data)) {
      this.merchantsReportSubject.next(true);
    } else {
      this.merchantsReportSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorSendReportByIAE = (error: HttpErrorResponse): void => {
    this.merchantsReportSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Merchants Report by Councils
  // ==========================================================================================

  public sendReportByCouncils(reportData: MerchantReportData): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.merchantHttp
        .sendReportByCouncils$(reportData)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextSendReportByCouncils,
          error: this.errorSendReportByCouncils,
        });
    }
  }

  private nextSendReportByCouncils = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus204(data)) {
      this.merchantsReportSubject.next(true);
    } else {
      this.merchantsReportSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorSendReportByCouncils = (error: HttpErrorResponse): void => {
    this.merchantsReportSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Register Merchant Format File
  // ==========================================================================================
  public getRegisterMerchantFormatFileUrl(): string {
    if (this.oAuthStorage.hasOAuth) {
      return this.merchantHttp.buildGetRegisterMerchantFormatFileUrl();
    }
  }

  // ==========================================================================================
  // Register Merchants
  // ==========================================================================================

  public getRegisterMerchants$(): Observable<string[]> {
    return this.registerMerchantsSubject.asObservable();
  }

  public getRegisterMerchants(multipart: FormData): void {
    this.enableLoading();
    this.merchantHttp
      .registerMerchants$(multipart)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe(this.successRegisterMerchants, this.failureRegisterMerchants);
  }

  private successRegisterMerchants = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const result = data.body ? data.body.result : null;
      this.registerMerchantsSubject.next(result);
    } else {
      this.registerMerchantsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private failureRegisterMerchants = (error: HttpErrorResponse): void => {
    this.registerMerchantsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  /** Get Flag */
  public getFlag$(): Observable<boolean> {
    return this.flagSubject.asObservable();
  }

  /** Reset Flag */
  public setFlag(value: boolean): void {
    this.flagSubject.next(value);
  }
}
