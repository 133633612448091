import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import {
  ActivatedRoute,
  convertToParamMap,
  ParamMap,
  Params,
  Router
} from '@angular/router';
import { PaginationLinks } from '@qaroni-core/types';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { TransactionService } from '../services/transaction.service';
import { Transaction } from './transaction';

export class TransactionDataSource extends DataSource<Transaction> {
  private hasMerchantID: boolean =
    this.route.snapshot.paramMap.has('merchantID');
  private merchantID: string = this.route.snapshot.paramMap.get('merchantID');

  public sort: MatSort;
  public paginator: MatPaginator;

  private transactions$: Observable<Transaction[]> = this.transactionService
    .getTransactions$()
    .pipe(shareReplay(1));

  private paginationLinks$: Observable<PaginationLinks> =
    this.transactionService.getPaginationLinks$().pipe(shareReplay(1));

  private subs: Subscription = new Subscription();

  private queryParams: Params;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transactionService: TransactionService
  ) {
    super();
  }

  connect(): Observable<Transaction[]> {
    this.paginator.pageSize = 60;
    this.paginator.pageIndex = 0;

    this.subs.add(this.route.queryParamMap.subscribe(this.getQueryParamMap));
    this.subs.add(this.paginationLinks$.subscribe(this.getPaginationLinks));
    this.subs.add(this.sort.sortChange.subscribe(this.getSort));
    this.subs.add(this.paginator.page.subscribe(this.getPage));

    return this.transactions$;
  }

  disconnect(): void {
    this.subs.unsubscribe();
  }

  private getQueryParamMap = (queryParamMap: ParamMap): void => {
    const queryParams: Params = {};
    if (queryParamMap.keys.length) {
      for (const key of queryParamMap.keys) {
        queryParams[key] = queryParamMap.get(key);
      }
    }
    this.queryParams = queryParams;

    this.transactionService.getTransactions(this.merchantID, this.queryParams);

    this.updateSort();
    this.updatePaginator();
  }

  private getPaginationLinks = (paginationLinks: PaginationLinks): void => {
    if (paginationLinks) {
      this.paginator.length = paginationLinks?.total;
    }
  }

  private redirect(queryParams: Params): void {
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  private getSort = (sortEvent: Sort): void => {
    if (sortEvent) {
      const queryParams: Params = { ...this.queryParams };

      if (sortEvent.direction) {
        queryParams.sortField = sortEvent.active;
        queryParams.sortDirection = sortEvent.direction.toUpperCase();
      } else {
        delete queryParams.sortField;
        delete queryParams.sortDirection;
      }

      this.redirect(queryParams);
    }
  }

  private getPage = (pageEvent: PageEvent): void => {
    if (pageEvent) {
      const queryParams: Params = { ...this.queryParams };

      queryParams.pagination = pageEvent.pageSize;
      queryParams.page = pageEvent.pageIndex + 1;

      this.redirect(queryParams);
    }
  }

  private updateSort(): void {
    const queryParamMap: ParamMap = convertToParamMap(this.queryParams);

    if (
      queryParamMap.has('sortField') &&
      queryParamMap.get('sortField').trim()
    ) {
      const active = queryParamMap.get('sortField').trim();
      if (active) {
        this.sort.active = active;
      }
    }

    if (
      queryParamMap.has('sortDirection') &&
      queryParamMap.get('sortDirection').trim()
    ) {
      const direction = queryParamMap.get('sortDirection').trim().toLowerCase();
      if (direction === '' || direction === 'asc' || direction === 'desc') {
        this.sort.direction = direction as SortDirection;
      }
    }
  }

  private updatePaginator(): void {
    const queryParamMap: ParamMap = convertToParamMap(this.queryParams);

    if (
      queryParamMap.has('pagination') &&
      queryParamMap.get('pagination').trim()
    ) {
      const pagination = queryParamMap.get('pagination').trim();
      if (pagination) {
        this.paginator.pageSize = parseInt(pagination, 10);
      } else {
        this.paginator.pageSize = 60;
      }
    } else {
      this.paginator.pageSize = 60;
    }

    if (queryParamMap.has('page') && queryParamMap.get('page').trim()) {
      const page = queryParamMap.get('page').trim();
      if (page) {
        this.paginator.pageIndex = parseInt(page, 10) - 1;
      } else {
        this.paginator.pageIndex = 0;
      }
    } else {
      this.paginator.pageIndex = 0;
    }
  }
}
