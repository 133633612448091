import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Merchant } from '@qaroni-core/entities';
import { AllAppService } from '@qaroni-core/services';
import { InputValidation } from '@qaroni-core/utils';

@Component({
  selector: 'qaroni-delete-merchant-dialog',
  templateUrl: './delete-merchant-dialog.component.html',
  styleUrls: ['./delete-merchant-dialog.component.scss'],
})
export class DeleteMerchantDialogComponent implements OnInit {
  public deleteForm: FormGroup;
  private deleteSkeleton = {
    nif: ['', Validators.required],
    sure: [false, [Validators.required, Validators.requiredTrue]],
  };

  public showForm = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteMerchantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public merchant: Merchant,
    private allApp: AllAppService,
    private fb: FormBuilder
  ) {
    this.createDeleteForm();
  }

  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  private createDeleteForm(): void {
    this.deleteForm = this.fb.group(this.deleteSkeleton);
  }

  get InputValidation(): any {
    return InputValidation;
  }

  get nif(): AbstractControl {
    return this.deleteForm.get('nif');
  }

  get sure(): AbstractControl {
    return this.deleteForm.get('sure');
  }

  get validatedForm(): boolean {
    return this.deleteForm.dirty && this.deleteForm.valid && this.nifMatch;
  }

  public onSubmit(): void {
    if (this.validatedForm && this.merchant?.merchantId) {
      this.dialogRef.close(this.merchant);
    }
  }

  public iAmResponsible(): void {
    this.showForm = true;
  }

  get nifMatch(): boolean {
    return this.merchant?.nif === this.nif?.value;
  }
}
