import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@qaroni-shared/material';
import { PipesModule } from '@qaroni-shared/pipes';
import { AddressPreviewComponent } from './address-preview/address-preview.component';
import { BranchesSelectorComponent } from './branches-selector/branches-selector.component';
import { ChangeLanguageComponent } from './change-language/change-language.component';
import { ChargeStatusSelectorComponent } from './charge-status-selector/charge-status-selector.component';
import { ClientsTableComponent } from './clients-table/clients-table.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { CardPdfDialogComponent } from './dialogs/card-pdf-dialog/card-pdf-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeleteClientDialogComponent } from './dialogs/delete-client-dialog/delete-client-dialog.component';
import { DeleteMerchantDialogComponent } from './dialogs/delete-merchant-dialog/delete-merchant-dialog.component';
import { ErrorsHttpDialogComponent } from './dialogs/errors-http-dialog/errors-http-dialog.component';
import { PayingDialogComponent } from './dialogs/paying-dialog/paying-dialog.component';
import { SendClientsReportDialogComponent } from './dialogs/send-clients-report-dialog/send-clients-report-dialog.component';
import { TextInputDialogComponent } from './dialogs/text-input-dialog/text-input-dialog.component';
import { GoBackButtonComponent } from './go-back-button/go-back-button.component';
import { GrecaptchaComponent } from './grecaptcha/grecaptcha.component';
import { ImageDefaultComponent } from './image-default/image-default.component';
import { InputErrorMessagesComponent } from './input-error-messages/input-error-messages.component';
import { OutsideFooterComponent } from './layout/outside-footer/outside-footer.component';
import { OutsideInitComponent } from './layout/outside-init/outside-init.component';
import { OutsideToolbarComponent } from './layout/outside-toolbar/outside-toolbar.component';
import { MerchantsTableComponent } from './merchants-table/merchants-table.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { TotalAmountsCardsComponent } from './total-amounts-cards/total-amounts-cards.component';
import { TotalAmountsShopCardsComponent } from './total-amounts-shop-cards/total-amounts-shop-cards.component';
import { TotalsMerchantCardsComponent } from './totals-merchant-cards/totals-merchant-cards.component';
import { TransactionsTableComponent } from './transactions-table/transactions-table.component';

@NgModule({
  declarations: [
    AddressPreviewComponent,
    BranchesSelectorComponent,
    CardPdfDialogComponent,
    ChangeLanguageComponent,
    ChargeStatusSelectorComponent,
    ClientsTableComponent,
    ConfirmationDialogComponent,
    DateRangeComponent,
    DeleteClientDialogComponent,
    DeleteMerchantDialogComponent,
    ErrorsHttpDialogComponent,
    GoBackButtonComponent,
    GrecaptchaComponent,
    ImageDefaultComponent,
    InputErrorMessagesComponent,
    MerchantsTableComponent,
    OutsideFooterComponent,
    OutsideInitComponent,
    OutsideToolbarComponent,
    PageTitleComponent,
    PayingDialogComponent,
    ProgressSpinnerComponent,
    SendClientsReportDialogComponent,
    TextInputDialogComponent,
    TotalAmountsCardsComponent,
    TotalAmountsShopCardsComponent,
    TotalsMerchantCardsComponent,
    TransactionsTableComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    AddressPreviewComponent,
    BranchesSelectorComponent,
    ChangeLanguageComponent,
    ChargeStatusSelectorComponent,
    ClientsTableComponent,
    DateRangeComponent,
    DeleteClientDialogComponent,
    DeleteMerchantDialogComponent,
    GoBackButtonComponent,
    GrecaptchaComponent,
    ImageDefaultComponent,
    InputErrorMessagesComponent,
    MerchantsTableComponent,
    OutsideFooterComponent,
    OutsideInitComponent,
    OutsideToolbarComponent,
    PageTitleComponent,
    PayingDialogComponent,
    ProgressSpinnerComponent,
    TextInputDialogComponent,
    TotalAmountsCardsComponent,
    TotalAmountsShopCardsComponent,
    TotalsMerchantCardsComponent,
    TransactionsTableComponent,
  ],
})
export class ComponentsModule {}
