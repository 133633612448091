import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarConfig } from '../../../core/config';

export const TransactionSnackbars = {
  successReportSent: {
    message: `Se envió el reporte al correo electrónico`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  successReturnTransaction: {
    message: `Transacción retornada con éxito.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureReturnTransaction: {
    message: `Error al intentar devolver una operación.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureReturnTransactionThereIsReturn: {
    message: `Ya existe una devolución para esta operación.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
};
