import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { AllAppService } from '@qaroni-core/services';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'qaroni-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit, OnDestroy {
  private dateRangeSkeleton = {
    dateFrom: [{ value: '', disabled: true }, Validators.required],
    dateTo: [{ value: '', disabled: true }, Validators.required],
  };
  public dateRangeForm: FormGroup = this.fb.group(this.dateRangeSkeleton);

  public minDate: moment.Moment = moment('2020-07-01');
  public maxDate: moment.Moment = moment();

  private subs: Subscription = new Subscription();

  private queryParamsHasPage: boolean;

  constructor(
    private allApp: AllAppService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.subs.add(this.route.queryParamMap.subscribe(this.getQueryParamMap));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getQueryParamMap = (queryParamMap: ParamMap): void => {
    this.queryParamsHasPage = queryParamMap.has('page');

    let momentFrom: moment.Moment;
    let momentTo: moment.Moment;
    if (queryParamMap.has('dateFrom') && queryParamMap.get('dateFrom').trim()) {
      momentFrom = moment(queryParamMap.get('dateFrom').trim());
      momentFrom.isValid()
        ? this.dateFrom.setValue(momentFrom)
        : this.dateFrom.setValue('');
    } else {
      this.dateFrom.setValue('');
    }
    if (queryParamMap.has('dateTo') && queryParamMap.get('dateTo').trim()) {
      momentTo = moment(queryParamMap.get('dateTo').trim());
      momentTo.isValid()
        ? this.dateTo.setValue(momentTo)
        : this.dateTo.setValue('');
    } else {
      this.dateTo.setValue('');
    }
    if (
      this.dateFrom?.value &&
      this.dateTo?.value &&
      this.dateFrom?.value?.isValid() &&
      this.dateTo?.value?.isValid() &&
      (!this.dateFrom?.value?.isSameOrBefore(this.dateTo.value) ||
        !this.dateTo?.value?.isSameOrAfter(this.dateFrom.value))
    ) {
      this.dateFrom.setValue('');
      this.dateTo.setValue('');
    }
  }

  get dateFrom(): AbstractControl {
    return this.dateRangeForm.get('dateFrom');
  }

  get dateTo(): AbstractControl {
    return this.dateRangeForm.get('dateTo');
  }

  public closeDatepicker(): void {
    if (
      this.dateFrom?.value &&
      this.dateTo?.value &&
      this.dateFrom?.value?.isValid() &&
      this.dateTo?.value?.isValid() &&
      this.dateFrom?.value?.isSameOrBefore(this.dateTo.value) &&
      this.dateTo?.value?.isSameOrAfter(this.dateFrom.value)
    ) {
      const queryParams: Params = {
        dateFrom: this.dateFrom.value.format('YYYY-MM-DD'),
        dateTo: this.dateTo.value.format('YYYY-MM-DD'),
      };
      if (this.queryParamsHasPage) {
        queryParams.page = 1;
      }
      this.allApp.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      });
    } else {
      this.dateFrom.setValue('');
      this.dateTo.setValue('');
    }
  }
}
