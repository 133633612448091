import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from '@qaroni-app/application/clients';
import { InputValidation } from '@qaroni-core/utils';

@Component({
  selector: 'qaroni-delete-client-dialog',
  templateUrl: './delete-client-dialog.component.html',
  styleUrls: ['./delete-client-dialog.component.scss'],
})
export class DeleteClientDialogComponent implements OnInit {
  public deleteForm: FormGroup;
  private deleteSkeleton = {
    nif: ['', Validators.required],
    sure: [false, [Validators.required, Validators.requiredTrue]],
  };

  public showForm = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public client: Client,
    private fb: FormBuilder
  ) {
    this.createDeleteForm();
  }

  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  private createDeleteForm(): void {
    this.deleteForm = this.fb.group(this.deleteSkeleton);
  }

  get InputValidation(): any {
    return InputValidation;
  }

  get nif(): AbstractControl {
    return this.deleteForm.get('nif');
  }

  get sure(): AbstractControl {
    return this.deleteForm.get('sure');
  }

  get validatedForm(): boolean {
    return this.deleteForm.dirty && this.deleteForm.valid && this.nifMatch;
  }

  public onSubmit(): void {
    if (this.validatedForm && this.client?.clientId) {
      this.dialogRef.close(this.client);
    }
  }

  public iAmResponsible(): void {
    this.showForm = true;
  }

  get nifMatch(): boolean {
    return this.client?.document === this.nif?.value;
  }
}
