import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'integerToCurrency',
})
export class IntegerToCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    if (!isNaN(value)) {
      return (value / 100).toFixed(2);
    }
  }
}
