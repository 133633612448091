import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllAppService } from '@qaroni-core/services';
import { InputValidation } from '@qaroni-core/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'qaroni-send-clients-report-dialog',
  templateUrl: './send-clients-report-dialog.component.html',
  styleUrls: ['./send-clients-report-dialog.component.scss']
})
export class SendClientsReportDialogComponent implements OnInit {

  /* Definitions. */
  public submitting = false;
  private subs: Subscription = new Subscription();
  public sendReportClientsForm: FormGroup;

  private sendReportSkeleton = {
    email: ['', [Validators.required, Validators.email]]
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SendClientsReportDialogComponent>,
    private allApp: AllAppService,
    private fb: FormBuilder
  ) {
    this.createSendClientsReportForm();
  }

  ngOnInit(): void {
  }

  getTitle() {
    return this.data;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private enableLoading(): void {
    this.allApp.progressBar.show();
    this.submitting = true;
  }

  onCancelClick(): void {
    this.dialogRef.close(null);
  }

  private createSendClientsReportForm(): void {
    this.sendReportClientsForm = this.fb.group(this.sendReportSkeleton);
  }

  get InputValidation(): any {
    return InputValidation;
  }

  get email(): AbstractControl {
    return this.sendReportClientsForm.get('email');
  }

  get validatedForm(): boolean {
    return this.sendReportClientsForm.dirty && this.sendReportClientsForm.valid && !this.submitting;
  }

  public onSubmit(): void {
    if (this.validatedForm && this.email && this.email.value) {
      this.enableLoading();
      this.dialogRef.close(this.email.value);
    }
  }


}
