import { Component, OnInit } from '@angular/core';
import { AllAppService } from '@qaroni-core/services';

@Component({
  selector: 'qaroni-go-back-button',
  templateUrl: './go-back-button.component.html',
  styleUrls: ['./go-back-button.component.scss'],
})
export class GoBackButtonComponent implements OnInit {
  constructor(private allApp: AllAppService) {}

  ngOnInit(): void {}

  public onBack(): void {
    this.allApp.location.back();
  }
}
