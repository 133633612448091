<button mat-button type="button" class="close-icon" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>
  ¿{{'Eliminar'|translate|uppercase}} {{client?.document}} - {{client?.firstName}} {{client?.lastName}}?
</div>

<form [formGroup]="deleteForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12 mb-4">
        <p class="mat-h3">{{'Se eliminará el cliente, así como todas sus operaciones'|translate}}.</p>
        <p class="font-weight-bold">{{'Esta es una acción que no se puede deshacer'|translate|uppercase}}</p>
      </div>
      <div class="col-12 mb-4" *ngIf="!showForm">
        <p>{{'Quedará registrado que aceptaste eliminar el cliente particular'|translate}}.</p>
        <p class="mb-4">{{'¿Asumes la responsabilidad que conlleva eliminar el cliente particular?'|translate}}</p>
        <div class="text-center">
          <button mat-flat-button color="warn" type="button"
            (click)="iAmResponsible()">{{'Sí, soy responsable'|translate}}</button>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" *ngIf="showForm">
      <div class="col-12">
        <p>
          {{'Como segundo factor de seguridad, debes indicar el DOCUMENTO del cliente que vas a eliminar'|translate}}.
          {{'Debe coincidir con el registrado'|translate}}.
        </p>
      </div>
      <div class="col-10 col-sm-8 col-md-8 mb-2">
        <mat-form-field appearance="outline">
          <mat-label>{{'Documento'|translate}}</mat-label>
          <input matInput formControlName="nif" autocomplete="off" type="text">
          <mat-error align="start" *ngIf="InputValidation.checkInputErrors(nif)">
            <qaroni-input-error-messages [fc]="nif"></qaroni-input-error-messages>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-10 col-sm-8 col-md-8 mb-4">
        <mat-checkbox formControlName="sure">
          {{'Acepto la responsabilidad que conlleva la eliminación'|translate}}
        </mat-checkbox>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" *ngIf="showForm">
    <div class="row justify-content-end align-items-center">
      <div class="col-auto">
        <button mat-button color="warn" type="button" (click)="onCancelClick()">{{'Cancelar'|translate}}</button>
      </div>
      <div class="col-auto">
        <button mat-flat-button color="accent" type="submit" [disabled]="!validatedForm">
          <mat-icon>delete</mat-icon> {{'Quiero eliminar'|translate}}
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</form>
