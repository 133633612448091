import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarConfig } from '@qaroni-core/config';

export const FileSnackbars = {
  errorFileFormatSize: {
    message: `Formatos admitidos: pdf, png, jpg, gif, svg. Tamaño máximo 2 MB.`,
    action: null,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: 8000,
    } as MatSnackBarConfig,
  },
  successUploadFile: {
    message: `Archivo agregado al contenido`,
    closeBtn: null,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: 6000,
    } as MatSnackBarConfig,
  },
  errorRegisterMerchantFileFormatSize: {
    message: `Formatos admitidos: Archivo XLSX (.xlsx). Tamaño máximo 2 MB.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: 8000,
    } as MatSnackBarConfig,
  },
  errorSelectedFileIsEmpty: {
    message: `El archivo seleccionado está vacío`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: 8000,
    } as MatSnackBarConfig,
  },
  errorFaileFormat: {
    message: `El formato del archivo seleccionado no es correcto. Por favor descargue el formato e intente nuevamente`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: 8000,
    } as MatSnackBarConfig,
  },
};
