<div
  class="mat-h2 my-7 text-center font-weight-light"
  *ngIf="paginator?.length === 0"
>
  {{ "No hay establecimientos que mostrar" | translate }}
</div>

<div class="table-responsive" [ngClass]="{ 'd-none': paginator?.length === 0 }">
  <table mat-table matSort class="full-width-table" aria-label="Merchants">
    <!-- CreationDate Column -->
    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Fecha de alta" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.creationDate | date: "mediumDate" }}</div>
        <div>{{ row.creationDate | date: "mediumTime" }}</div>
      </td>
    </ng-container>

    <!-- Has Bonuses Column -->
    <ng-container matColumnDef="hasBonuses">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Operaciones" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.hasBonuses }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Nombre Fiscal" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <!-- Nif Column -->
    <ng-container matColumnDef="nif">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "CIF" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.nif }}</td>
    </ng-container>

    <!-- Phone Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Teléfono" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.phone }}</td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Correo electrónico" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.email }}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Estado" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.status | userStatus | translate }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="text-right">
        <button
          *ngIf="showViewPdf(row)"
          mat-mini-fab
          color="none"
          type="button"
          class="mat-elevation-z0"
          (click)="openPdfContract(row)"
          [matTooltip]="'Abrir contrato' | translate"
          matTooltipPosition="before"
        >
          <mat-icon>picture_as_pdf</mat-icon>
        </button>

        <button
          mat-mini-fab
          color="none"
          class="mat-elevation-z0"
          type="button"
          [routerLink]="[
            '/dashboard/merchants',
            row?.merchantId,
            'transactions'
          ]"
          [matTooltip]="detailsTooltip(row)"
          matTooltipPosition="before"
        >
          <mat-icon>navigate_next</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div [ngClass]="{ 'd-none': paginator?.length === 0 || !showPaginator }">
  <mat-paginator
    #paginator
    [pageSizeOptions]="[25, 50, 100, 250]"
  ></mat-paginator>
</div>
