import { Component, OnInit, Input } from '@angular/core';
import { Merchant } from '@qaroni-core/entities';

@Component({
  selector: 'qaroni-image-default',
  templateUrl: './image-default.component.html',
  styleUrls: ['./image-default.component.scss'],
})
export class ImageDefaultComponent implements OnInit {
  @Input() merchant: Merchant;

  constructor() {}

  ngOnInit(): void {}
}
