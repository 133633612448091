import { Pipe, PipeTransform } from '@angular/core';
import { UserStatusEnum, UserStatusInfo } from '@qaroni-app/application/auth';

@Pipe({
  name: 'userStatus',
})
export class UserStatusPipe implements PipeTransform {
  transform(value: UserStatusEnum): string {
    if (value) {
      const key = value.trim().toUpperCase();
      for (const iterator of UserStatusInfo) {
        if (Object.keys(iterator)[0] === key) {
          return iterator[key].name;
        }
      }
    }
    return null;
  }
}
