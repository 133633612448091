import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStatusEnum } from '@qaroni-app/application/auth';
import {
  Merchant,
  MerchantDataSource,
  MerchantService,
  MerchantSnackbars
} from '@qaroni-core/entities';
import { AllAppService } from '@qaroni-core/services';
import { GeneralConstants } from '@qaroni-shared/constants/GeneralConstants';
import { KeysConstants } from '@qaroni-shared/constants/KeysConstants';

@Component({
  selector: 'qaroni-merchants-table',
  templateUrl: './merchants-table.component.html',
  styleUrls: ['./merchants-table.component.scss'],
})
export class MerchantsTableComponent implements OnInit, AfterViewInit {
  @Input() showPaginator = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Merchant>;
  public dataSource: MerchantDataSource;

  public displayedColumns: string[] = [
    'creationDate',
    'hasBonuses',
    'name',
    'nif',
    'phone',
    'email',
    'status',
    'actions',
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private merchantService: MerchantService,
    private allApp: AllAppService
  ) {}

  ngOnInit(): void {
    this.dataSource = new MerchantDataSource(
      this.route,
      this.router,
      this.merchantService
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  public detailsTooltip(merchant: Merchant): string {
    let tooltip = 'Ver detalles';
    if (merchant?.tradename) {
      tooltip += ' de ' + merchant.tradename;
    }
    return tooltip;
  }

  public showViewPdf(merchant: Merchant): boolean {
    return (
      merchant &&
      merchant[KeysConstants.KEY_SIGNATURE_ID] &&
      merchant.status === UserStatusEnum.ACTIVE
    );
  }

  public openPdfContract(merchant: Merchant): void {
    if (merchant[KeysConstants.KEY_SIGNATURE_ID]) {
      const url: string =
        GeneralConstants.URL_FIRMARY_DOCUMENTS +
        merchant[KeysConstants.KEY_SIGNATURE_ID];
      window.open(url);
    } else {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          MerchantSnackbars.failureOpenContractBecauseDontExists.message
        ),
        MerchantSnackbars.failureOpenContractBecauseDontExists.closeBtn,
        MerchantSnackbars.failureOpenContractBecauseDontExists.config
      );
    }
  }
}
