import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorsHttpDialogComponent } from '@qaroni-shared/components/dialogs';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ManageErrorsInterceptor implements HttpInterceptor {
  private disabledRegExpRoutes: RegExp[] = [
    /\/users\/\d+\/passwords/i,
    /\/forgots/i,
    /\/logins/i,
    /\/(clients|merchants)\/registers/i,
    /\/users\/\d+\/validates/i,
  ];

  constructor(private dialog: MatDialog) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let errorInfo: HttpErrorResponse = null;

    return next.handle(req).pipe(
      tap(
        () => {},
        (error) => {
          if (error instanceof HttpErrorResponse) {
            errorInfo = error;
          }
        }
      ),
      finalize(() => {
        if (errorInfo !== null) {
          console.error('INTERCEPTOR', errorInfo);

          if (
            !this.errorIsTokenInvalid(errorInfo.status) &&
            !this.urlBelongsToDisabledRegExpRoutes(req.url)
          ) {
            const matDialogConfig = new MatDialogConfig();
            matDialogConfig.data = errorInfo;
            matDialogConfig.width = '700px';
            matDialogConfig.maxWidth = '90vw';
            matDialogConfig.panelClass = 'style-close-dialog';
            matDialogConfig.autoFocus = false;
            matDialogConfig.disableClose = true;

            this.dialog.open(ErrorsHttpDialogComponent, matDialogConfig);
          }
        }
      })
    );
  }

  private errorIsTokenInvalid(status: number): boolean {
    if (status === 401 || status === 403) {
      return true;
    } else {
      return false;
    }
  }

  private urlBelongsToDisabledRegExpRoutes(url: string): boolean {
    let belong = false;

    for (const regExpRoute of this.disabledRegExpRoutes) {
      if (regExpRoute.test(url)) {
        belong = true;
        break;
      }
    }

    return belong;
  }
}
