import { Component, Input, OnInit } from '@angular/core';
import { Merchant } from '@qaroni-core/entities';

@Component({
  selector: 'qaroni-address-preview',
  templateUrl: './address-preview.component.html',
  styleUrls: ['./address-preview.component.scss'],
})
export class AddressPreviewComponent implements OnInit {
  @Input() merchant: Merchant;

  constructor() {}

  ngOnInit(): void {}
}
