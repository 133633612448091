export enum MerchantTypeEnum {
  SHOP = 'SHOP'
}

export type MerchantType =
  | MerchantTypeEnum.SHOP;

export const MerchantTypeArray = [
  MerchantTypeEnum.SHOP
];

export const MerchantTypeInfo = [
  {
    [MerchantTypeEnum.SHOP]: {
      name: 'Minorista',
    },
  },
];
