import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiQBonoEnv } from '@qaroni-core/config';
import { Observable } from 'rxjs';
import {
  ChangePasswordJson,
  ForgotJson,
  LoginJson,
  OtpJson,
  OtpUsernameJson,
  ResetPasswordJson,
  SignUpJson
} from '../types/authentication';
import { UserRoleEnum } from '../types/user-role.enum';

@Injectable({
  providedIn: 'root',
})
export class OAuthHttpService {
  constructor(private http: HttpClient) {}

  public register$(signUpJSON: SignUpJson): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/townhalls/registers`;

    return this.http.post(url, signUpJSON, { observe: 'response' });
  }

  public login$(loginJSON: LoginJson): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/logins`;

    return this.http.post(url, loginJSON, { observe: 'response' });
  }

  public validate$(
    userID: number | string,
    otpJSON: OtpJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/validates`;

    return this.http.patch(url, otpJSON, { observe: 'response' });
  }

  public forgot$(forgotJSON: ForgotJson): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/forgots`;

    return this.http.post(url, forgotJSON, { observe: 'response' });
  }

  public resetPassword$(
    userID: number | string,
    resetPasswordJSON: ResetPasswordJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/passwords/resets`;

    return this.http.post(url, resetPasswordJSON, {
      observe: 'response',
    });
  }

  public otp$(userID: number | string): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/otp`;

    const roleJSON = {
      role: UserRoleEnum.TOWN_HALL,
    };

    return this.http.post(url, roleJSON, { observe: 'response' });
  }

  public otpUsername$(
    otpUsernameJSON: OtpUsernameJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/users/otp`;

    return this.http.post(url, otpUsernameJSON, { observe: 'response' });
  }

  public changePassword$(
    userID: number | string,
    changePasswordJSON: ChangePasswordJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/passwords`;

    return this.http.patch(url, changePasswordJSON, {
      observe: 'response',
    });
  }

  public deleteUser$(userID: number | string): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}`;

    return this.http.delete(url, { observe: 'response' });
  }
}
