<button mat-button type="button" class="close-icon" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>Liquidar importe a {{merchant?.nif}} / {{merchant?.name}}</div>

<form [formGroup]="payingForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="row justify-content-center">
      <div class="col-10 col-sm-8 col-md-8 mb-2">
        <mat-form-field appearance="outline">
          <mat-label>Importe liquidado</mat-label>
          <input matInput formControlName="amount" autocomplete="off" type="number" step="0.01" min="1"
            [max]="maxAmount">
          <mat-error align="start" *ngIf="InputValidation.checkInputErrors(amount)">
            <qaroni-input-error-messages [fc]="amount"></qaroni-input-error-messages>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-10 col-sm-8 col-md-8 mb-2">
        <mat-form-field appearance="outline">
          <mat-label>Descripción</mat-label>
          <input matInput formControlName="description" autocomplete="off" type="text">
          <mat-error align="start" *ngIf="InputValidation.checkInputErrors(description)">
            <qaroni-input-error-messages [fc]="description"></qaroni-input-error-messages>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-10 col-sm-8 col-md-8 mb-4">
        <mat-form-field appearance="outline">
          <mat-label>Número de referencia</mat-label>
          <input matInput formControlName="authorizationCode" autocomplete="off" type="text">
          <mat-error align="start" *ngIf="InputValidation.checkInputErrors(authorizationCode)">
            <qaroni-input-error-messages [fc]="authorizationCode"></qaroni-input-error-messages>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="row justify-content-end align-items-center">
      <div class="col-auto">
        <button mat-button color="warn" (click)="onCancelClick()" type="button">Cancelar</button>
      </div>
      <div class="col-auto">
        <button mat-flat-button color="accent" [disabled]="!validatedForm" type="submit">
          <mat-icon>save</mat-icon>
          Guardar
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</form>
