import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStatusEnum } from '@qaroni-app/application/auth';
import {
  Card,
  CardPdfDataDialog,
  Client,
  ClientDataSource,
  ClientService
} from '@qaroni-app/application/clients';
import { AllAppService } from '@qaroni-core/services';
import { ConfirmationDialogData } from '@qaroni-core/types';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../dialogs';
import { CardPdfDialogComponent } from '../dialogs/card-pdf-dialog/card-pdf-dialog.component';

@Component({
  selector: 'qaroni-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.scss'],
})
export class ClientsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showPaginator = true;

  @Output() cancel: EventEmitter<Client> = new EventEmitter();
  @Output() active: EventEmitter<Client> = new EventEmitter();
  @Output() delete: EventEmitter<Client> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Client>;
  public dataSource: ClientDataSource;

  public displayedColumns: string[] = [
    'name',
    'pdf',
    'document',
    'email',
    'phone',
    'status',
    'actions',
  ];

  public UserStatusEnum = UserStatusEnum;

  private subs: Subscription = new Subscription();
  submitting = false;

  constructor(
    private allApp: AllAppService,
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    this.dataSource = new ClientDataSource(
      this.route,
      this.router,
      this.clientService
    );
    this.subs.add(this.clientService.getWallet$().subscribe(this.getWallet));
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private enableLoading(): void {
    this.allApp.progressBar.hide();
    this.submitting = true;
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
    this.submitting = false;
  }

  public cancelClient(client: Client): void {
    this.cancel.emit(client);
  }

  public activeClient(client: Client): void {
    this.active.emit(client);
  }

  public deleteClient(client: Client): void {
    this.delete.emit(client);
  }

  public hasCards(client: Client): boolean {
    return !!client?.cards?.length;
  }

  public cardIsStatic(client: Client): boolean {
    if (this.hasCards(client)) {
      return client.cards[0].isStatic ? true : false;
    } else {
      return false;
    }
  }

  public pdfColumnString(client: Client): string {
    if (this.hasCards(client)) {
      return client.cards[0].isStatic ? 'Si' : 'No';
    } else {
      return 'No tiene bono';
    }
  }

  public onQrPdf(client: Client): void {
    if (
      client?.clientId &&
      this.hasCards(client) &&
      client?.cards[0]?.cardId &&
      client?.cards[0]?.wallet &&
      !this.cardIsStatic(client)
    ) {
      const url: string = this.clientService.getDownloadPdf(
        client?.clientId,
        client?.cards[0]?.cardId,
        client?.cards[0]?.wallet
      );

      const confirmationData: CardPdfDataDialog = {
        title: `¿Activar el Bono en PDF?`,
        message: `Confirmas que deseas Activar el Bono de ${client?.firstName} ${client?.lastName} - ${client?.document} en un archivo PDF`,
        downloadUrl: url,
        confirmMatIcon: `qr_code`,
        confirmText: `Sí, activar`,
      };

      const matDialogConfig = new MatDialogConfig();
      matDialogConfig.width = '700px';
      matDialogConfig.maxWidth = '90vw';
      matDialogConfig.panelClass = 'style-close-dialog';
      matDialogConfig.data = confirmationData;

      const dialog = this.allApp.dialog.open<
        CardPdfDialogComponent,
        CardPdfDataDialog,
        boolean
      >(CardPdfDialogComponent, matDialogConfig);

      this.subs.add(
        dialog.afterClosed().subscribe((confirmation) => {
          if (confirmation) {
            window.location.reload();
          }
        })
      );
    }
  }

  public downloadPdf(client: Client): string {
    if (
      client?.clientId &&
      this.hasCards(client) &&
      this.cardIsStatic(client) &&
      client?.cards[0]?.cardId &&
      client?.cards[0]?.wallet
    ) {
      return this.clientService.getDownloadPdf(
        client?.clientId,
        client?.cards[0]?.cardId,
        client?.cards[0]?.wallet
      );
    }
  }

  public refreshWallet(client: Client): void {
    if (
      client &&
      client.clientId &&
      client.cards &&
      client.cards.length === 1
    ) {
      const card = client.cards[0];
      if (card && card.cardId) {
        this.openDialogConfirmationToRefreshWallet(
          client.clientId,
          card.cardId
        );
      }
    }
  }

  openDialogConfirmationToRefreshWallet(
    clientId: number,
    cardId: number
  ): void {
    const confirmationData: ConfirmationDialogData = {
      title: this.allApp.translate.instant('Refrescar wallet'),
      message: this.allApp.translate.instant(
        'Confirmas que deseas refrescar el wallet?'
      ),
      confirmMatIcon: `refresh`,
      confirmText: this.allApp.translate.instant('Sí, refrescar'),
    };

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.width = '700px';
    matDialogConfig.maxWidth = '90vw';
    matDialogConfig.panelClass = 'style-close-dialog';
    matDialogConfig.data = confirmationData;

    const dialog = this.allApp.dialog.open(
      ConfirmationDialogComponent,
      matDialogConfig
    );

    this.subs.add(
      dialog.afterClosed().subscribe((confirmation) => {
        if (confirmation === true) {
          this.enableLoading();
          this.clientService.refreshWallet(clientId, cardId);
        }
      })
    );
  }

  private getWallet = (card: Card): void => {
    this.disableLoading();
  }
}
